.txt-header {
  font-size: 25px;
  text-align: center;
  color: #2e394c;
  margin-bottom: 25px;
  font-family: "nunito-semiBold";
}

.MuiFormControl-marginNormal {
  width: 100%;
}

.import-region-select {
  width: 70%;
  margin-top: 15px;
}

.MuiFormControl-root.region-select {
  width: 100%;
  margin-top: 15px;

  .MuiFormLabel-root.Mui-focused,
  .MuiInputLabel-formControl.MuiInputLabel-shrink {
    background-color: white;
    padding: 0 10px;
  }

  .MuiSelect-select:focus {
    background-color: unset;
  }
}

.MuiFormControl-root.region-download-select {
  width: 72%;
  margin-top: 15px;

  .MuiFormLabel-root.Mui-focused,
  .MuiInputLabel-formControl.MuiInputLabel-shrink {
    background-color: white;
    padding: 0 10px;
  }

  .MuiSelect-select:focus {
    background-color: unset;
  }
}

.create-tenant .MuiPaper-root.MuiMenu-paper.MuiPaper-rounded {
  top: 430.5px !important;
}

.create-support-user {
  label {
    display: block;
  }

  .form-textfield {
    width: 100%;
  }

  .ant-select {
    margin-top: 0 !important;
  }

  .mb-20 {
    margin-bottom: 24px;
  }
  .grid-2-columns {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 18px 1rem;
    &::before,
    &:after {
      content: normal;
    }
    #createSupportUser,
    #roleMenu {
      grid-column: 1 / -1;
    }
  }
}

.cancle-btn {
  color: #40a9ff;
  background-color: #fff;
  border-color: #40a9ff;
  width: 35%;
  margin-right: 60px;
  height: 34px;
  font-size: 11px;
  font-weight: 600;
}

.create-btn {
  background-image: linear-gradient(#2a94e4e3, #0675c7);
  font-size: 11px;
  font-weight: 600;
  float: left;
  width: 35%;
  margin-left: 60px;
  height: 34px;
}

.ant-modal-body {
  padding: 35px 50px;
  padding-bottom: 0px;
}

.ant-modal-footer {
  padding: 20px 16px;
  border-top: unset;
}

.create-tenant,
.create-application {
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: rgba(39, 35, 35, 0.13);
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
    border-width: 1px;
  }
}

.success-icon {
  background-image: url("../../../image/success.svg");
  height: 15px;
  width: 15px;
  background-size: 15px;
}

.success-icon.position {
  position: absolute;
  right: 10px;
  bottom: 15px;
}

.success-icon.position.error {
  top: 28px;
}

.success-icon.error {
  background-image: url("../../../image/error.svg");
  background-repeat: no-repeat;
}

.position-rel {
  position: relative;
}

.user-position-rel {
  position: relative;
}

.ant-modal-content {
  .MuiOutlinedInput-input {
    padding: 12px 14px 12px;
  }

  .MuiInputLabel-formControl {
    top: -5px;
  }

  .MuiFormLabel-root {
    font-size: 14px;
  }

  .MuiFormControl-marginNormal {
    margin-bottom: 0;
  }

  .MuiFormHelperText-contained {
    margin: 3px 14px 0;
  }

  .MuiFormHelperText-root {
    font-size: 11px;
  }

  .MuiFormControl-marginNormal {
    margin-top: 15px;
  }

  .MuiInputLabel-formControl.MuiInputLabel-shrink {
    top: 0px !important;
  }

  .MuiInputBase-input {
    color: #475162;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #1890ff;
  }

  .ant-select {
    width: 100%;
    margin-top: 15px;
  }

  .ant-select-selection--single {
    padding: 4px 0;
    height: unset;
    border-color: rgba(39, 35, 35, 0.13);
  }

  .ant-select-selection:hover {
    border-color: rgba(0, 0, 0, 0.23);
  }

  .ant-select-selection-selected-value {
    font-size: 14px;
  }

  .ant-select-selection__placeholder {
    color: rgba(0, 0, 0, 0.54);
  }
}

.file-custom {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #555;
  background-color: #fff;
  border: 0.075rem solid #ddd;
  border-radius: 0.25rem;
  box-shadow: inset 0 0.2rem 0.4rem rgba(0, 0, 0, 0.05);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.file {
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 2.5rem;
}

.user-upload-file {
  position: relative;
  display: inline-block;
  width: 30%;
  float: right;
}

.user-download-file {
  position: relative;
  display: inline-block;
  float: right;
  top: 16px;
}

.user-upload-btn {
  float: right;
  border: 1px solid #0581df;
  padding: 10px 30px;
  color: #0581df;
  border-radius: 6px;
  cursor: pointer;
}

.user-download-btn {
  float: right;
  border: 1px solid #0581df;
  padding: 9px 15px;
  color: #0581df;
  border-radius: 6px;
  cursor: pointer;
}

.form-csv-text {
  color: #bcc9d9;
  font-style: italic;
}

.form-import-error-text {
  color: #e42a31;
  font-style: italic;
  margin-top: 20px;
}

.import-loder-box {
  margin-top: 10px;
  font-size: 23px;
  color: #0581df;
}

.import-loder-txt {
  margin-left: 20px;
  color: #2e394c;
  font-size: 16px;
}

.file {
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 2.5rem;
  width: 100%;
  margin-top: 20px;
}

.file input {
  min-width: 14rem;
  margin: 0;
  filter: alpha(opacity=0);
  opacity: 0;
}

.file-custom {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #555;
  background-color: #fff;
  border: 0.075rem solid #ddd;
  border-radius: 0.25rem;
  box-shadow: inset 0 0.2rem 0.4rem rgba(0, 0, 0, 0.05);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.file-custom:before {
  position: absolute;
  top: -0.075rem;
  right: -0.075rem;
  bottom: -0.075rem;
  z-index: 6;
  display: block;
  content: "BROWSE";
  height: 2.5rem;
  padding: 0.5rem 1.5rem;
  line-height: 1.5;
  color: #1c89dc;
  background-color: #ffffff;
  border: 0.075rem solid #1886d9;
  border-radius: 0 0.25rem 0.25rem 0;
}

.file-custom:after {
  content: "Select File";
  color: #b3b3b3;
}

.ant-select-lg .ant-select-selection--single {
  height: 40px;
}

.ant-select-lg {
  font-size: 16px !important;
}

.MuiOutlinedInput-root {
  height: 40px !important;
}

.MuiInput-root {
  margin-top: 15px !important;
  width: 100%;
  font-size: 14px;
  border: 1px solid #dedede;
  height: 40px;
  border-radius: 4px;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 0px solid rgba(0, 0, 0, 0.87) !important;
}

.MuiInput-underline:before {
  border-bottom: unset !important;
}

.form-phonefield {
  .PhoneInputInput {
    height: 40px;
    padding: 12px 14px 12px;
    border: 1px solid #e4e4e4;
    border-radius: 0.5rem !important;
    color: #475162;
    font-size: 16px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;

    &::placeholder {
      color: rgba(0, 0, 0, 0.54);
    }
  }

  .PhoneInputInput:focus {
    outline: none;
  }
}
.error-top-margin{
  margin-top: 5px;
}
