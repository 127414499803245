.login-container-support {
	background-color: #f2f6fa;
	position: absolute;
	width: 100%;
	height: 100%;

	.login-box {
		width: 20%;
		margin: auto;
		text-align: center;
	}

	.txt-login {
		color: #2e394b;
		font-size: 24px;
		font-family: 'nunito-bold';
		margin: 50px 0;
	}

	.sso-login-box {
		box-shadow: 0 2px 4px -2px rgb(0 0 0 / 10%);
		background-color: #fff;
		padding: 15px;
		border-radius: 4px;
		margin-bottom: 15px;
		cursor: pointer;
		border: 1px solid #ececec;
		display: flex;
		justify-content: center;
	}

	.image-logo-container {
		display: inline-block;
	}

	.login-icons {
		height: 20px;
		margin-right: 10px;
	}

	.sso-name {
		display: inline-block;
		text-align: center;
		width: 80%;
		font-family: "nunito-bold";
		font-size: 15px;
		color: #364d58;
	}

	.support-logo {
		margin-top: 30px;
	}

	.txt-forgot {
		color: #8a99ab;
		font-size: 12px;
		float: right;
		cursor: pointer;
	}
}