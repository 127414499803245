@import "./variables.scss";

.App-header {
  background-color: $primary-color;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.oloid-box {
  padding: 25px;

  img {
    height: 70px;
    float: left;
  }

  .text-oloid {
    font-family: nunito-bold;
    font-size: 25px;
    position: relative;
    top: 10px;
    color: rgb(46, 57, 75);
  }
}

.btn-save {
  background: transparent linear-gradient(180deg, #249af3 0%, #007ddc 100%) 0%
    0% no-repeat padding-box;
  border: 1px solid #0b87e4;
  border-radius: 3px !important;
  opacity: 1;
  font-family: "nunito-black";
  width: 90px;
  font-size: 11px;
  color: #fff;
  height: 32px;
  cursor: pointer;
}
.ui_block.ui-block-loader {
  display: none;
}
.ui-block-loader {
  display: flex;
  align-items: center;
  overflow: auto;
  z-index: 99999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #ffffff87;
}
.ant-select {
  width: 100%;
  .ant-select-selection {
    box-shadow: none !important;
    border: 1px solid #d9d9d9 !important;
    padding-top: 3px;
    border-radius: 0.5rem;
    .ant-select-selection__clear {
      top: 50%;
    }
    .ant-select-selection__rendered {
      margin-bottom: 0;
      line-height: 34px;
      margin-left: 0.75rem;
      .ant-select-selection__placeholder {
        margin-left: 0;
      }
      > ul > li {
        max-height: 24px;
        margin-top: 5px;
        .ant-select-selection__choice__content {
          font-size: 14px;
          line-height: 22px;
        }
        &.ant-select-search {
          margin-top: 0;
          max-height: 100%;
        }
        &:not(.ant-select-search) {
          background-color: #c4dbf4;
          border: 0;
          border-radius: 1rem;
          display: flex;
          align-items: center;
          .ant-select-selection__choice__remove {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}
.ant-select-dropdown {
  border: 1px solid #d0d5dd;
  border-radius: 0.5rem;
  > div {
    border-radius: 0.5rem;
  }
  .ant-select-dropdown-menu {
    .ant-select-dropdown-menu-item {
      padding: 0.6875rem 0.875rem;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      font-family: "Lato-Regular", sans-serif;
      font-size: 1rem;
      background-color: #fff;
      &:hover {
        background-color: transparent;
      }
      &:nth-child(2n) {
        background-color: #f9fafb !important;
      }
      &.ant-select-dropdown-menu-item-active:not(
          .ant-select-dropdown-menu-item-disabled
        ) {
        background-color: transparent;
      }
      &.ant-select-dropdown-menu-item-selected {
        font-weight: normal;
        .tick {
          border-color: #0b6fd0;
          &:after {
            opacity: 1;
            border-color: #0b6fd0;
            width: 4.71428571px;
            height: 9.14285714px;
          }
        }
      }
    }
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: transparent;
    font-weight: normal;
    .tick {
      border-color: #0b6fd0;
      &:after {
        opacity: 1;
        border-color: #0b6fd0;
        width: 4.71428571px;
        height: 9.14285714px;
      }
    }
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: transparent;
    font-weight: normal;
  }
  .ant-select-item-option {
    padding: 0.6875rem 0.875rem;
    position: relative;
    &:nth-child(even) {
      background-color: #f9fafb;
    }
    .ant-select-item-option-content {
      font-family: "Lato-Regular", sans-serif;
      font-size: 1rem;
    }
  }
}
iframe[style^="position"] {
  display: none !important;
}
.ant-select-selection--single {
  min-height: 42px;
  height: unset !important;
}
.anticon {
  vertical-align: middle;
}
.position-relative {
  position: relative;
  margin-bottom: 24px;
  .MuiFormControl-root {
    margin-bottom: 5px !important;
  }
  .error-msg {
    position: absolute;
    top: 100%;
    left: 0;
  }
}
.error-msg {
  color: red;
  font-size: 12px;
}
.MuiOutlinedInput-root {
  .MuiOutlinedInput-input {
    border-radius: 0.5rem !important;
  }
  border-radius: 0.5rem !important;
  .MuiOutlinedInput-notchedOutline {
    border-color: #d9d9d9 !important;
  }
}
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
