.login-container {
  background-color: #f2f6fa;
  position: absolute;
  width: 100%;
  height: 100%;
  .login-box {
    width: 43%;
    margin: auto;
    margin-top: 75px;
    box-shadow: 0 10px 20px -2px rgba(0, 0, 0, 0.14);
    background-color: #ffffff;
    padding: 45px;
    .form-wrapper {
      .position-rel {
        margin-bottom: 12px;
      }
    }
  }

  .txt-login {
    color: #2e394b;
    font-size: 25px;
    margin-bottom: 35px;
    font-family: nunito-black;
  }

  .txt-forgot {
    color: #8a99ab;
    font-size: 12px;
    float: right;
    cursor: pointer;
  }

  .remember-box {
    margin: 20px 0 35px;

    .square-box {
      height: 20px;
      width: 20px;
      border: 1px solid #ebeff6;
      float: left;
      margin-right: 15px;
      padding: 4px;
      cursor: pointer;

      .green-box {
        height: 10px;
        width: 10px;
        background-color: #65c465;
        border-radius: 2px;
      }
    }
  }

  .btn-login {
    background-image: linear-gradient(#2799f2, #0c77e3);
    color: white;
    font-size: 11px;
    font-family: nunito-black;
    padding: 0px 65px;
    cursor: pointer;
    height: 35px;
  }

  .txt-desc {
    color: #798aa0;
    margin-bottom: 10px;
    font-size: 14px;
  }

  .txt-login.txt-change {
    margin-bottom: 30px;
  }

  .mg-top {
    margin-top: 30px;
  }

  .mg-bottom {
    margin-bottom: 30px;
  }

  .txt-success-msg {
    color: rgb(53, 63, 81);
    font-size: 14px;
    margin: 30px 0;
  }

  .MuiFormControl-root {
    width: 100%;

    .MuiFormLabel-root.Mui-focused {
      top: 0px;
      color: #1890ff;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: rgba(0, 0, 0, 0.87);
      border-width: 1px;
    }
    .MuiInputLabel-formControl {
      top: -6px;
      font-size: 14px;
    }

    .MuiOutlinedInput-input {
      padding: 10px;
    }

    ::placeholder {
      font-size: 14px;
    }
  }
}
