.tenant-top-bar{
    width: 100%;
}

.tenant-top-bar .txt-tenant-header{
    font-size: 22px;
    color: black;
    position: relative;
    left: 20px;
    display: inline-block;
}

.tenant-top-bar .left-bar{
    display: inline-flex;
}

.tenant-top-bar .menu-bar-icon{
    -webkit-mask-box-image: url('../../../image/flowDesigner/menu.svg');
    background-color: black;
    height: 12px;
    width: 20px;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 1px;
}

.btn-box{
    float: right;
}

.btn-add-tenant{
    background-image: linear-gradient(#2799f2, #1B76E7);
    padding: 0px 25px;
    border-radius: 2px;
    color: white;
    line-height: 34px;
    position: relative;
    top: 12px;
    cursor: pointer;
    display: inline-block;
    float: right;
    height: 34px;
    font-size: 11px;
    font-family: nunito-black;
    text-transform: uppercase;
}