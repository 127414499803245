$primary-color: #282c34;
$secondary-color: #61dafb;

$white-color: #FFFFFF;

$font-family-nunito-black: 'nunito-black';
$font-family-nunito-black-italic: 'nunito-black-italic';
$font-family-nunito-bold: 'nunito-bold';
$font-family-nunito-bold-italic: 'nunito-bold-italic';
$font-family-nunito-extra-bold: 'nunito-extra-bold';
$font-family-nunito-extra-bold-italic: 'nunito-extra-bold-italic';
$font-family-nunito-extra-light: 'nunito-extra-light';
$font-family-nunito-extra-light-italic: 'nunito-extra-light-italic';
$font-family-nunito-italic: 'nunito-italic';
$font-family-nunito-light: 'nunito-light';
$font-family-nunito-light-italic: 'nunito-light-italic';
$font-family-nunito-regular: 'nunito-regular';
$font-family-nunito-semiBold: 'nunito-semiBold';
$font-family-nunito-semi-bold-italic: 'nunito-semi-bold-italic';